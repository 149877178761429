// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_nBnRK{display:flex;flex-direction:row;flex-grow:1;height:100%;width:100%}.menuRight_agMzk{background-color:var(--color-supportive-3-300);border-right:1px solid var(--color-supportive-3-900);height:100vh;padding:1.5rem;width:15rem}.menu_jf3Y5,.menuRight_agMzk{display:flex;flex-direction:column}.menu_jf3Y5{gap:1rem;height:100%}.pullBottom_eOziS{align-items:flex-end;display:flex;flex-grow:1}.menuItem_Z-l-v{cursor:pointer;display:flex;flex-direction:row;justify-content:space-between;width:100%}.selected_3oYF1{background-color:var(--color-yellow-500);font-weight:300!important}.menuItem_Z-l-v:hover{background-color:var(--color-yellow-300)}.menuItem_Z-l-v:hover,.selected_3oYF1{border-radius:var(--border-radius-medium);margin:-.5rem 0 -.5rem -.5rem;padding:.25rem .5rem;width:calc(100% + 1rem)}.search_m7VJJ{margin:0 0 0 -.5rem;width:calc(100% + 1rem)}.content_p8o1T{display:flex;flex-direction:column;flex-grow:1;height:100%;width:calc(100% - 15rem)}.item_vkmzz{margin-top:1rem;min-height:10rem;width:calc(33% - .5rem)}@media screen and (min-width:1441px){.item_vkmzz{width:calc(25% - .75rem)}}@media screen and (max-width:1024px){.item_vkmzz{width:calc(50% - .5rem)}}.row_-DGBQ{display:flex;flex:0 0 auto;flex-flow:row wrap;gap:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_nBnRK",
	"menuRight": "menuRight_agMzk",
	"menu": "menu_jf3Y5",
	"pullBottom": "pullBottom_eOziS",
	"menuItem": "menuItem_Z-l-v",
	"selected": "selected_3oYF1",
	"search": "search_m7VJJ",
	"content": "content_p8o1T",
	"item": "item_vkmzz",
	"row": "row_-DGBQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
